import * as React from "react";
import "./Nav.sass";
import logo from "../images/logo_bolt.svg";
import { Link } from "gatsby";
import { useState, useRef } from "react";
import { telephonePrest, meetingPrest } from "../helpers";
import arrow from "../images/arrow_up.png";

const Nav = () => {
  const [open, setOpen] = useState(false);
  const [openNav, setOpenNav] = useState(false);
  const [openDomain, setOpenDomain] = useState(false);
  const timer = useRef(null);

  const enterMenuButton = () => {
    clearTimeout(timer.current);
    setOpenDomain(true);
  };

  const enterMenu = () => {
    clearTimeout(timer.current);
  };

  const closeDomain = () => {
    timer.current = setTimeout(() => {
      setOpenDomain(false);
    }, 300);
  };

  return (
    <nav>
      <div id="logo-nav">
        <Link to="/">
          <img src={logo} alt="logo"></img>
        </Link>
        <div
          id="nav-button"
          className={openNav ? "openNav" : "closedNav"}
          onClick={() => {
            setOpenNav(!openNav);
          }}
          onKeyDown={() => {
            setOpenNav(!openNav);
          }}
          role="button"
          tabIndex={0}
          aria-label="Open navigation"
        ></div>
      </div>
      <ul className={openNav ? "openNav" : "closedNav"}>
        <li
          className="n"
          id="domains-item"
          onMouseEnter={() => enterMenuButton()}
          onMouseLeave={() => closeDomain()}
        >
          <div
            id="domain-button"
            className={openDomain ? "openDomain" : "closedDomain"}
            onClick={() => {
              if (openDomain) {
                setOpenDomain(false);
              } else {
                setOpenDomain(true);
              }
            }}
          >
            Prestations
          </div>
          <div
            id="mobile-domains"
            className={openDomain ? "openDomain" : "closedDomain"}
          >
            <ul>
              <li>
                <Link to="/domaines/constitution-societes">
                Votre entreprise - droit des sociétés
                </Link>
              </li>
              <li>
                <Link to="/domaines/vos-contrats">
                 Vos contrats et documents juridiques - droit des contrats
                </Link>
              </li>
              <li>
                <Link to="/domaines/ressources-humaines">
                 Vos ressources humaines - droit du travail
                </Link>
              </li>
              <li>
                <Link to="/domaines/locaux">Vos locaux - droit du bail</Link>
              </li>
              <li>
                <Link to="/domaines/debiteurs">Vos débiteurs - recouvrement de créances</Link>
              </li>
              <li>
                <Link to="/domaines/propriete-intellectuelle">
                Votre propriété intellectuelle - droit d'auteur - droit des marques
                </Link>
              </li>
              <li>
                <Link to="/domaines/immobilier">Votre immobilier - droit immobilier</Link>
              </li>
              <li>
                <Link to="/domaines/constructions">Vos constructions - droit de la construction</Link>
              </li>
              <li>
                <Link to="/domaines/creanciers">Vos créanciers - poursuites et faillites</Link>
              </li>
              <li>
                <Link to="/domaines/contentieux">Votre contentieux - procédures judiciaires et administratives</Link>
              </li>
            </ul>
          </div>
          <div
            id="domains-holder"
            className={openDomain ? "" : "hidden"}
            onMouseLeave={() => {
              closeDomain();
            }}
            onMouseEnter={enterMenu}
          >
            <ul>
              <li>
                <Link to="/domaines/constitution-societes">
                Votre entreprise - droit des sociétés
                </Link>
              </li>
              <li>
                <Link to="/domaines/vos-contrats">
                Vos contrats et documents juridiques - droit des contrats
                </Link>
              </li>
              <li>
                <Link to="/domaines/ressources-humaines">
                Vos ressources humaines - droit du travail
                </Link>
              </li>
              <li>
                <Link to="/domaines/locaux">Vos locaux - droit du bail</Link>
              </li>
              <li>
                <Link to="/domaines/debiteurs">Vos débiteurs - recouvrement de créances</Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/domaines/propriete-intellectuelle">
                Votre propriété intellectuelle - droit d'auteur - droit des marques
                </Link>
              </li>
              <li>
                <Link to="/domaines/immobilier">Votre immobilier - droit immobilier</Link>
              </li>
              <li>
                <Link to="/domaines/constructions">Vos constructions - droit de la construction</Link>
              </li>
              <li>
                <Link to="/domaines/creanciers">Vos créanciers - poursuites et faillites</Link>
              </li>
              <li>
                <Link to="/domaines/contentieux">Votre contentieux - procédures judiciaires et administratives</Link>
              </li>
            </ul>
          </div>
        </li>
        <li className="nav-item">
          <Link to="/domaines/vos-contrats/contrats-et-documents">
            Contrats et documents
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/domaines/abonnement">Abonnements</Link>
        </li>

        <li className="nav-item">
          <Link to="/apropos">À propos</Link>
        </li>
        <li className="nav-item">
          <Link to="/nous-trouver">Nous trouver</Link>
        </li>

        <li id="actions">
          <div
            role="button"
            tabIndex={0}
            id="cta"
            onClick={() => setOpen(!open)}
          >
            Contacter un avocat
          </div>
          <ul className={open ? "open" : ""}>
            <li>
              <Link
                to="/checkout"
                state={telephonePrest()}
                onClick={() => {
                  setOpenNav(false);
                  setOpen(false);
                }}
              >
                Entretien téléphonique <img src={arrow}></img>
              </Link>
            </li>
            <li>
              <Link
                to="/checkout"
                state={meetingPrest()}
                onClick={() => {
                  setOpenNav(false);
                  setOpen(false);
                }}
              >
                Entretien en personne <img src={arrow}></img>
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
