import * as React from "react";
import "@fontsource/dm-sans";
import "./Layout.sass";
import "../Styles.sass";
import Nav from "./Nav";
import Footer from "./Footer";
import CookieConsent from "react-cookie-consent";
import { Link } from "gatsby";
import Helmet from "react-helmet";

const Layout = ({ children, title, meta, description, lang }) => {
  const acceptStyle = {
    background: "#FAFC45",
    color: "black",
    fontFamily: "DM Sans",
  };

  const declineStyle = {
    background: "#666666",
    color: "white",
    fontFamily: "DM Sans",
  };

  const overlay = {
    zIndex: "1005",
  };
  // TODO: check for empyt string from metaDescription, and use default as
  return (
    <div id="layout">
      <Helmet
        title={"legalify.ch | " + title}
        description={description}
        htmlAttributes={{
          lang,
        }}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
        ].concat(meta)}
      />
      <CookieConsent
        location="bottom"
        buttonText="OK"
        declineButtonText="Non, merci"
        cookieName="gdpr-okay"
        buttonClasses="consent-btn"
        declineButtonId="decline-btn"
        buttonId="accept-btn"
        containerClasses="consent-container"
        style={overlay}
        buttonStyle={acceptStyle}
        declineButtonStyle={declineStyle}
        enableDeclineButton
      >
        En poursuivant votre navigation sur notre site, vous acceptez
        l'utilisation de cookies. Vous pouvez en savoir plus sur leur
        fonctionnement et sur la protection de vos données dans notre{" "}
        <Link to="/pdc">
          <span id="pdc">Politique de confidentialité.</span>
        </Link>
      </CookieConsent>
      <Nav></Nav>
      {children}
      <Footer></Footer>
    </div>
  );
};

Layout.defaultProps = {
  lang: `fr`,
  meta: [],
  title: "Conseils juridiques en ligne - Avocats en Suisse",
  description: `Conseils et services juridiques en ligne pour entreprises et particuliers par des avocats à Genève et en Suisse. Création de sociétés et contrats.`,
};

export default Layout;
